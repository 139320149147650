label {
  @include type-scale(15);
  color: color(elephant);
  display: block;
  cursor: pointer;
  font-weight: 500;

  .label__explanation {
    color: color(bombay);
    @include type-scale(13);
    vertical-align: top;
    line-height: rhythm(1);
    &:before {
      content: "\00a0";
    }
  }

  &.label--prefix {
    padding: rhythm(0.5);
    min-width: rhythm(2);
    text-align: center;
    border: 1px solid color(mercury);
    @include leading-border(1px, 0.5);
    @include trailing-border(1px, 0.5);
    display: inline-block;
    font-weight: 700;
    border-radius: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 0;
    background: color(alabaster);
    text-shadow: 1px 1px 0px white;

    & + input[type="text"] {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

input[type="text"],
input[type="password"],
input[type="email"],
textarea,
select {
  @include type-scale(16);
  color: color(elephant);
  font-family: $body-font;
  font-weight: 300;
  background: color(alabaster);
  border-radius: 5px;
  padding: rhythm(0.5);
  display: inline-block;
  width: 100%;
  border: 1px solid color(mercury);
  // border: 1px solid darken(color(mercury), 5%);
  @include leading-border(1px, 0.5);
  @include trailing-border(1px, 0.5);

  &:focus {
    border: 1px solid color(picton);
    box-shadow: inset 0 0 3px color(picton);
  }

  &.input--error {
    border: 1px solid color(monza);

    &:focus {
      box-shadow: inset 0 0 3px rgba(color(monza), 0.5);
    }
  }

  &.input--narrow {
    max-width: 200px;
  }

   &.input--inline {
    max-width: 200px;
    display: inline;
    vertical-align: top;
    margin-right: rhythm(1);
  }

  &.input-error {
    border: 1px solid color(monza);
  }

  &.input-error ~ .input-error-message {
    visibility: visible;
    opacity: 1;
    font-size: 12px;
    display: inline-flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: rhythm(0.25);
    color: color(monza);
  }

  &:disabled {
    color: fade-out(color(elephant), .5);
  }
}

.input-error-message {
    visibility: hidden;
    opacity: 0;
    font-size: 12px;
    justify-content: flex-end;
    width: 100%;
    margin-top: rhythm(0.25);
    color: color(monza);
    transition: opacity 0.33s linear;
    position: absolute;
    right: 0;
    bottom: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  background: color(alabaster) url('../img/arrow.svg') no-repeat 97% 14px;

  @include breakpoint($tablet) {
    background: color(alabaster) url('../img/arrow.svg') no-repeat 97% 16px;
  }
}

input[type="checkbox"] {
  display: none;

  & + label {
    // margin: rhythm(1) 0;
    cursor: pointer;
    display: inline-block;
    @include type-scale(14);
    user-select: none;
    -webkit-user-select: none;

    span.checkbox__span {
      display: inline-block;
      width: rhythm(1);
      height: rhythm(1);
      vertical-align: bottom;
      position: relative;
      top: -1px;
      background: url('../img/checkbox.svg') left top no-repeat;
    }
  }

  &:checked + label span.checkbox__span {
    background: url('../img/checkbox.svg') left bottom no-repeat;
  }
}

.form {
  &__row {
    text-align: left;
    margin-bottom: rhythm(1);
  }

  &__actions {
    margin-top: rhythm(2);
    border-color: color(mercury);
    padding-top: rhythm(1);
    @include leading-border(1px, 1);
    margin-bottom: rhythm(1);
  }

  &__explanation {
    @include type-scale(14);
    margin-bottom: rhythm(1);
    color: darken(color(bombay), 10%);
    font-weight: 500;

    &--positive {
      color: color(lima);
    }
  }

  &__inner {
    padding: 0 gutter();
    border-color: color(mercury);
    margin-top: rhythm(2);
    @include leading-border(1px, 1);
    @include trailing-border(1px, 1);
  }

  &__dropzone {
    text-align: center;
    padding: rhythm(3) 0;
    border: 2px dashed color(mercury);
    border-radius: 5px;
    @include leading-border(2px, 3, 16px, dashed);
    @include trailing-border(2px, 3, 16px, dashed);
    background-color: color(alabaster);
    font-weight: 700;
    cursor: pointer;
    margin-bottom: rhythm(1);
  }

  &--auth {
    max-width: rhythm(16);
    margin: rhythm(5) auto;
  }

  &__error {
    display: block;
    color: color(mandy);
    @include type-scale(14);
    font-weight: 500;

    &--block {
      @include type-scale(14);
      background: rgba(color(monza), 0.2);
      color: color(mandy);
      border-color: color(mandy);

      margin-bottom: rhythm(1);
      // margin-left: - rhythm(1);
      // margin-right: - rhythm(1);
      padding: rhythm(1);
      // @include leading-border(1px, 0.5);
      // @include trailing-border(1px, 0.5);
    }
  }
}

.password-input {
  position: relative;
}

.password-input__toggle {
  position: absolute;
  top: rhythm(0.25);
  right: rhythm(0.25);
  display: inline-block;
  min-width: rhythm(3.5);
  padding: 0 rhythm(0.5);
  border-radius: 3px;
  cursor: pointer;
  @include type-scale(13);
  line-height: rhythm(1.5);
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  color: white;
  user-select: none;
  -webkit-user-select: none;
  background: color(bombay);
  &:hover {
    background: darken(color(bombay), 10%);
  }
}
