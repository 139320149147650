.agency-partnership {

  &__flex-direction {
    display: flex;
    flex-direction: column;
    @include breakpoint($tablet) {
      flex-direction: row;
    }
  }

  &--fixed-height {
  .agency-partnership__item {
      @include breakpoint($tablet) {
        min-height: 140px;
      }
    }
  }

  &__wrap {
    padding-bottom: rhythm(1.25);
  }

  &__header {
    text-align: center;
    border-radius: 4px 4px 0 0;
    color: white;
    padding: rhythm(0.5) rhythm(1);
    margin: 0;

    &__text {
      @include type-scale(16);
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
  
    @include breakpoint($tablet) {
      flex-direction: row;
    }
  
    border-left: solid 1px color(mercury);
    border-right: solid 1px color(mercury);
    border-bottom: solid 1px color(mercury);
    border-radius: 0 0 4px 4px;
  }

  &__item {
    flex: 100%;
    padding: rhythm(0.5) rhythm(0.75);
    text-align: center;

    @include breakpoint($tablet) {
      flex: 50%;
      padding: rhythm(1) rhythm(0.75);
    }

    p {
      font-weight: 500;
      color: color(william);
      @include type-scale(14);
    }
  }

  &__item--divider {
    border-bottom: solid 1px color(mercury);
  }

  &__item__header {
    @include type-scale(16);
    line-height: rhythm(1.2);
    font-weight: bold;
    color: color(denim);
    margin: 0;
  }

  &__item__unit {
    @include type-scale(28);
    line-height: rhythm(1.2);
    font-weight: bold;
    color: color(elephant);
    margin-bottom: rhythm(0.4);
  }
}
