.payment {

  &__update {
    padding-top: 12px;
  }

  &__US-account {
    padding-top: 24px;
  }

  &__Non-US-account {
    padding-top: 24px;
  }

  &__iban-option {
    margin-left: 24px;
  }

  .form__row {
    margin-bottom: 0px;
    position: relative;
    padding-bottom: 1.25rem;
  }
}
