
.header {
  // height: rhythm(6);
  border-bottom: 1px solid color(mercury);
  margin-bottom: rhythm(1);

  @include breakpoint($tablet-landscape) {
    // height: rhythm(4);
    margin-bottom: rhythm(2);
  }
}

.header__logo {
  display: block;
  margin: rhythm(1) auto;

  height: 40px;

  &.wide {
    display: none;
  }

  @include breakpoint($header-logo-breakpoint) {
    margin-top: 18px;
    margin-bottom: 18px;
    &.wide {
      display: block;
    }
    &.narrow {
      display: none;
    }
  }

  @include breakpoint($tablet-landscape) {
    margin-top: 18px;
    margin-bottom: 18px;

    &.wide {
      display: inline-block;
    }
  }
}
