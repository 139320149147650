.flash {
  margin-bottom: rhythm(2);
  padding: rhythm(1) 0 rhythm(1);
  text-align: center;
  color: #fff;
  background-color: color(picton);

  @include breakpoint($tablet) {
    margin-top: - rhythm(2);
  }

  p {
    margin: 0 auto;
    max-width: 60%;
    font-weight: 500;
  }
  &.error {
    background-color: color(monza);
  }
}

.flash.in-modal {
  color: inherit;
  font-weight: 500;
  margin-top: 0;
  margin-left: - rhythm(2);
  margin-right: - rhythm(2);
  margin-bottom: rhythm(2);
  text-align: left;
  padding: 0 rhythm(2);

  p {
    max-width: 100%;
  }
}

.flash.in-auth {
  margin-top: 0;
}
