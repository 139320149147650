// Use border-box for everything
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

// Resets margins and paddings for textual elements
body, h1, h2, h3, h4, h5, h6, ul, li, p {
  margin: 0;
  padding: 0;
}

// Removes all borders and radiuses of all form items.
input,
textarea,
select,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  outline: 0;
  border: 0;
  &:hover,
  &:active,
  &:focus {
    outline: 0;
  }
}

// Set Img height and width to auto for IE8
img {
  height: auto;
  width: auto;
}
