.sidebar {
  @include breakpoint($tablet) {
    @include span(2);
  }
}

.sidebar__nav {
  margin-top: rhythm(0.5); // To align it with the header
  margin-bottom: rhythm(1);
  @include breakpoint($tablet) {
    position: relative;
    top: - rhythm(0.5);
    // margin-top: rhythm(0.5);
  }
}

.sidebar__link {
  display: block;
  @include type-scale(14);
  color: color(elephant);
  border-color: color(alabaster);
  padding-top: rhythm(0.5);
  @include trailing-border(1px, 0.5);
  text-align: left;

  &:hover {
    background: rgba(color(alabaster), 0.75);
    padding-left: 7px;
    transition: padding 0.1s linear;
  }

  &.active {
    padding-left: 7px;
    font-weight: bold;
  }

}
