.loader {
  margin-right: 5px;
  width: rhythm(0.75);
  height: rhythm(0.75);
  vertical-align: text-bottom;
  display: inline-block;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  overflow: hidden;
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  border-right: 2px solid transparent;
  animation: spin 0.75s infinite linear;
  border-radius: 50%;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader--big {
  border-top: 2px solid color(denim);
  border-left: 2px solid color(denim);
  border-bottom: 2px solid color(denim);
  border-right: 2px solid transparent;
  width: rhythm(2);
  height: rhythm(2);
}
