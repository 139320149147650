.navigation {
  @include breakpoint($tablet-landscape) {
    margin-top: rhythm(1);
    display: inline-block;
    float: right;
  }
}

.navigation__item {
  display: inline-block;
  text-align: center;
  @include span(4 of 12);

  &:last-child {
    @include span(4 of 12 last);
  }

  @include breakpoint($tablet-landscape) {
    height: rhythm(2);
    width: auto;
    margin: 0;
    &:last-child {
      width: auto;
      margin: 0;
    }
  }
}

.navigation__link {
  @include type-scale(14);
  color: color(elephant);
  text-transform: uppercase;
  display: block;
  padding: rhythm(0.5) rhythm(1);
  font-weight: 500;

  &--active {
    background: rgba(color(alabaster), 0.75);
    font-weight: 700;
  }

  &:hover {
    // background: rgba(color(alabaster), 0.75);
    text-decoration: underline;
  }
}
