.code-editor {
  position: relative;
  overflow: hidden;
  min-height: rhythm(9);
}

.code-editor__textarea,
.code-editor__pre {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-y: scroll !important;
  @include type-scale(13);
}

.code-editor__textarea {
  outline: none;
  resize: none;
  color: darken(color(alabaster), 30%);
  margin: 0;
  z-index: 1;
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

code[class*="language-"], pre[class*="language-"] {
  @include type-scale(13);
}

.code-editor__pre {
  background: transparent;
  border: 1px solid transparent;
  padding: rhythm(0.5);
  @include leading-border(1px, 0.5);
  @include trailing-border(1px, 0.5);
  @include type-scale(13);
  z-index: 2;
  pointer-events: none;
  min-height: 100%;
  margin: 0 !important;
  overflow-y: auto;
  background: transparent !important;
}

.code-editor__code {
  @include type-scale(13);
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  display: block;
}

.code-snippet {
  background: color(aqua-haze);
  padding: rhythm(0.5);
}
