// ==========================
// TYPOGRAPHY VARIABLES
// -----
// This file is for you to set up all typography related
// values that are used in your project. It uses Compass
// Vertical Rhythms to help create typography.
//
// All font variables should be included in this file.
// Examples are:
//
// font-size
// font-weight
// font-family,
// line-height
// ... and others
//
// Feel free to add or change any variables declared
// within this file.
//
// ==========================

// ==========================
// DEFAULT TYPOGRAPHY SETTINGS - The default settings for this projects are:
// ==========================

$base-font-size: 16px;
$base-line-height: 20px;
$round-to-nearest-half-line: true;

// Uses the `rem` unit for all vertical rhythm output
$rhythm-unit: "rem";

// Sets different font families for us with
$body-font: "Roboto", sans-serif;
$code-font: monospace;
;

// ==========================
// Sets Typograhy sizes
// -----
// Adapted from https://medium.com/p/8e51498c3713 to create
// typography variables that can be easily used in other
// areas of your project.
//
// DEPENDENCY:
// ------
// This uses the @include adjust-font-size-to mixin and
// requires Compass Vertical Rhythm to be used. A version
// of compass vertical rhythms that is decoupled from Compass
// is installed into this starter by default.
//
//
// USAGE:
// ------
// This will output h1 font size and line height
//
// .example {
//   @include type-scale(h1);
// }
//
// Refer to helpers/mixins for the mixin used.
// ==========================

$type-scale: (
  11: (
    font-size: 11px,
    base-lines: 1
    ),
  12: (
    font-size: 12px,
    base-lines: 1
    ),
  13: (
    font-size: 13px,
    base-lines: 1
    ),
  14: (
    font-size: 14px,
    base-lines: 1
    ),
  15: (
    font-size: 15px,
    base-lines: 1
    ),
  16: (
    font-size: 16px,
    base-lines: 1
    ),
  18: (
    font-size: 18px,
    base-lines: 1
    ),
  19: (
    font-size: 19px,
    base-lines: 1
    ),
  24: (
    font-size: 24px,
    base-lines: 2
    ),
  28: (
    font-size: 28px,
    base-lines: 2
    ),
  32: (
    font-size: 32px,
    base-lines: 2
    ),
  base: (
   font-size: $base-font-size,
   base-lines: 1
   ),
  );
