.confirm-button--wrapper {
  display: inline-flex;
  flex-flow: column;

  > a:not([disabled]) {
    cursor: pointer;
  }
  > a[disabled],
    button[disabled] {
    opacity: .7;
  }
  > span {
    margin-top: .5rem;
  }
}