// ==========================
// TYPOGRAPHY BASE
// -----
// This file sets the typography for the rest of the document.
// Properties that should be present are only:
//
// font-family
// font-size
// line-height
// font-weight
// font-style
// font-variant
// ... and other font properties
//
// If your typography changes in size when the breakpoint
// changes, you should include these changes right here in this
// document.
//
// NOTE
// -----
// This place is for all the typographic elements only!
// Variables should still be declared in variables/typography!
// ==========================

body {
  font-family: $body-font;
  font-weight: 300;
  color: color(william);
  text-rendering: geometricPrecision;
  // text-rendering: optimizeLegibility;

  padding-bottom: rhythm(2);
}

.wf-loading {
  visibility: hidden;
}

// Typography styling
h1, .header--primary {
  @include type-scale(24);
    font-weight: 500;
    border-color: color(mercury);
    padding-bottom: rhythm(1);
}

h2, .header--secondary {
  @include type-scale(14);
  font-weight: 700;
  text-transform: uppercase;
  color: color(elephant);
  margin-bottom: rhythm(1);
}

h3 {
  @include type-scale(24);
  font-weight: 100;
  margin-bottom: rhythm(1);
}

h4 {
  @include type-scale(19);
  font-weight: 500;
  margin-bottom: rhythm(1);
}

h5 {
  @include type-scale(14);
  font-weight: 700;
  text-transform: uppercase;
  border-color: color(elephant);
  margin-bottom: rhythm(0.5);
  @include trailing-border(1px, 0.5);
}

h6 {
  @include type-scale(11);
}

p {
  @include type-scale(15);
}

ul {
  list-style: none;
}

a, .link {
  color: color(picton);
  text-decoration: none;
}

.link--primary {
  color: color(buttercup);
}

.link--secondary {
  color: color(christi);
}

pre,
code {
  @include type-scale(base);
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
}

code {
  display: block;
  white-space: pre;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
  overflow-x: auto;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

pre {
  white-space: pre;
}

code {
  overflow: hidden;
}

/* Text helpers */
.text--left {
  text-align: left;
}

.text--centered {
  text-align: center;
}

.text--right {
  text-align: right;
}

.text--faded {
  color: color(bombay);
}

.pull-right {
  float: right;
}

.text--monospace {
  font-family: $code-font;
}

.text--small {
  @include type-scale(13);
}

.text--inline {
  display: inline;
}

.text--reset {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}

.state-box {
  margin-right: 2px;
  color: color(mercury);

  &--active {
    color: color(lima);
  }
}

.border-none {
  border: none;
}

.bold {
  font-weight: bold;
}
