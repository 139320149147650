.ReactModal__Overlay {
  background-color: transparent !important;

  &:after {
    position: absolute;
    content: ' ';
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    @include linear-gradient(#1E8DC7, #133951);
  }
}

.ReactModal__Body--open {
  // We need to target an element adjacent to the modal content/wrapper,
  // otherwise the contents of the modal will get blurred, which is undesired.
  #root {
    filter: blur(2px);
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='svgMask'><feGaussianBlur stdDeviation='2' /></filter></svg>#svgMask");
  }
}

.ReactModal__Content {
  position: relative;
  z-index: 100;
  overflow: hidden;
}

.ReactModal__Close {
  position: absolute;
  top: rhythm(0.5);
  right: rhythm(0.5);
  z-index: 120;
  cursor: pointer;
}
