.radio {
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      font-size: 1rem;
      &:before {
        content: '';
        background: color(alabaster);
        border-radius: 100%;
        border: 1px solid darken(color(alabaster), 25%);
        display: inline-block;
        width: 1em;
        height: 1em;
        position: relative;
        margin-right: 1em; 
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: color(elephant);
          box-shadow: inset 0 0 0 2px color(alabaster);
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: color(elephant);
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 2px color(alabaster);
          border-color: darken(color(alabaster), 25%);
          background: darken(color(alabaster), 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}
