$triangle-size: 8px;
$border-color: color(bombay);

// TODO: put this in correct files/directories
%triangle-arrow {
  margin-left: -$triangle-size;
  position: absolute;

  &,
  &::before {
    box-sizing: content-box;
    position: absolute;
    border: $triangle-size solid transparent;

    height: 0;
    width: 1px;
  }

  &::before {
    content: "";
    z-index: -1;
    border-width: $triangle-size;

    left: -$triangle-size;
    border-bottom-color: $border-color;
  }
}

%triangle-arrow-up {
  @extend %triangle-arrow;

  top: 0;
  margin-top: -$triangle-size;

  &,
  &::before {
    border-top: none;
    border-bottom-color: white;
  }

  &::before {
    top: -1px;
    border-bottom-color: $border-color;
  }
}

%triangle-arrow-down {
  @extend %triangle-arrow;

  bottom: 0;
  margin-bottom: -$triangle-size;

  &,
  &::before {
    border-bottom: none;
    border-top-color: #fff;
  }

  &::before {
    bottom: -1px;
    border-top-color: $border-color;
  }
}



.datepicker {
  width: 320px;
  padding: rhythm(1);
  padding-top: 0;
  background-color: white;
  position: relative;
  box-shadow: 0px 0px 5px 0px color(bombay);
}

.datepicker__container {
  position: absolute;
  display: inline-block;
  z-index: 2147483647;
}

.datepicker__triangle {
  position: absolute;
  left: 50px;
}

.tether-element-attached-top .datepicker__triangle {
  @extend %triangle-arrow-up;
}

.tether-element-attached-bottom .datepicker__triangle {
  @extend %triangle-arrow-down;
}

.tether-target-attached-top.datepicker__container {
  margin-top: -20px;
}

.datepicker__header {
  width: 100%;
  text-align: center;
  position: relative;
}

.datepicker__current-month {
  @include type-scale(19);
  padding: rhythm(1) 0;
  font-weight: 300;
}

.datepicker__navigation {
  line-height: rhythm(1);
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: rhythm(1.25);
  width: 0;
  border: 6px solid transparent;

  &--previous {
    left: 0;
    border-right-color: color(william);

    &:hover {
      border-right-color: color(william);
    }
  }

  &--next {
    right: 0;
    border-left-color: color(william);

    &:hover {
      border-left-color: color(william);
    }
  }
}

.datepicker__header,
.datepicker__month {
  & > div {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}

.datepicker__day {
  display: table-cell;
  text-align: center;
  cursor: pointer;
  padding: rhythm(0.5) 0;
  @include type-scale(15);
  color: color(bombay);
  font-weight: 500;

  &:hover,
  &--today {
    background-color: color(alabaster);
  }

  &--selected,
  &--in-range {
    background-color: color(picton);
    color: #fff;

    &:hover {
      background-color: darken(color(picton), 10%);
    }
  }

  &--disabled {
    cursor: default;
  }

  .datepicker__header & {
    font-weight: 700;
    @include type-scale(14);
    color: color(william);

    &:hover {
      background-color: inherit;
      cursor: default;
    }
  }
}

.datepicker__input-container {
  width: auto;
  display: inline-block;
  position: relative;
  margin-left: rhythm(0.5);
  margin-right: rhythm(0.5);
  input {
    @include leading-border(1px, 0.25);
    @include trailing-border(1px, 0.25);
    font-weight: 500;
    @include type-scale(12);

  }
}

.datepicker__input {
  &:not(:valid) ~ .close-icon {
    display: none;
  }
}

.react-date-picker__multi-month-view {
  border-bottom: 1px solid $border-color;
}

.react-date-picker-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: white;
  margin-right: 10px;
}

.date-picker-button-toggle {
  background: transparent;
  border-radius: 0;
  padding: rhythm(0.5) rhythm(2) rhythm(0.5) rhythm(0.5);
  @include type-scale(16);
  border-color: #808080;
  color: color(elephant);
  border: 1px solid color(mercury);
  &:hover {
    background: transparent;
  }
  .triangle-arrow-down {
    @extend %triangle-arrow-down;
    margin-bottom: 2*$triangle-size;
    margin-left: 2*$triangle-size;
    &::before {
      border-top-color: color(elephant);
    }
  }
  .triangle-arrow-up {
    @extend %triangle-arrow-up;
    margin-top: 2*$triangle-size;
    margin-left: 2*$triangle-size;
    &::before {
      border-bottom-color: color(elephant);
    }
  }
}

.react-date-picker-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: rhythm(0.25);
  width: 100%;
  padding: rhythm(1) rhythm(2.5);
  border-left: 1px solid #808080;
  border-right: 1px solid #808080;
  border-bottom: 1px solid #808080;
}

.react-date-picker-footer__btn-cancel {
  background: transparent;
  color: color(regent);
  font-weight: 700;
  @include type-scale(12);
  padding: rhythm(0.25);
  width: rhythm(5);

  &:hover {
    background: transparent;
  }
}

.react-date-picker-footer__btn-apply {
  padding: rhythm(0.25);
  width: rhythm(5);
  @include type-scale(12);
}

.react-date-picker__footer--theme-default .react-date-picker__footer-button {
  background: #63b2f3;
  border: none;
  width: rhythm(3);
  @include type-scale(12);
}

.react-date-picker__footer--theme-default .react-date-picker__footer-button + .react-date-picker__footer-button {
  background: transparent;
  color: color(regent);
  font-weight: 700;
  &:hover {
    background: transparent;
    color: color(bombay);
  }
}

.react-calendar__tile {
  color: currentColor;
  font-size: 1em;
  font-weight: 300;
  border-radius: 0;
  margin: 0 0.125rem;
  max-width: 80%;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 0.5em 0.25em;
}

.react-calendar__month-view__days__day--weekend {
  color: color(monza);
}

.react-calendar__tile--active {
  color: white;
}

.react-calendar__navigation__label {
  color: currentColor;
}

.react-calendar__navigation {
  button {
    color: currentColor;
  }
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: white;
}
