.content {
  @include breakpoint($tablet) {
    @include span(6);
  }
}

.content--wide {
  @include breakpoint($tablet) {
    @include span(10 last);
  }
}
