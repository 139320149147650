.cdbl-privacy-notice {
  margin-top: 3rem;

  text-align: left;
  font-size: 0.7rem;
  color: grey;
  line-height: 1.2em;

  ul {
    list-style: disc;
    list-style-position: inside;
  }
}


