// ==========================
// COLORS
// -----
// Set up all colors that are used in the project.
// http://chir.ag/projects/name-that-color/
//
// USAGE:
// ------
//
// .test {
//   background-color: color(shamrock);
// }
// ==========================

$colors: (
  william:      #39596E, // Main text color
  elephant:     #133951, // Headings, labels, input field text
  bombay:       #AFB6BB, // Light gray text, checkboxes under fields
  regent:       #90989D, // Table information
  denim:        #118DC9, // Darker version of blue, for borders
  matisse:      #1578A4, // Sidebar and dropdown backgrounds
  orient:       #00638D, // background: for header gradient and accordion background
  deluge:       #6F6BB5,
  aqua-haze:    #FAFCFC, // Barely visible blue (for comment backgrounds)
  alabaster:    #F7F7F7, // Light gray backgrounds
  mercury:      #E7E7E7, // Light gray borders (slighly darker than background)
  lily-white:   #E8F7FF,
  picton:       #36A9E1, // (disabled 70%) buttons, Light blue bg, links
  buttercup:    #F5A623, // Primary orange (for buttons)
  tangerine:    #EE9400, // Orange for text
  christi:      #78A30D, // Primary green (for text)
  lima:         #89B716, // Secondary green (for buttons)
  limeade:      #859900,
  jungle-green: #2AA198,
  monza:        #D0021B, // (20% backgrounds), red text
  mandy:        #E25960, // error text (validation), borders on validation
  desert-storm: #F8F8F7, // background for pre code elements
  flamingo:     #F05A28, // Referoo orange
  );
