table {
  border-collapse: collapse;
  width: 100%;
}

tr {
  tbody &:nth-child(even) {
    background-color: color(alabaster);
  }
  &.updated {
    background: #BBB !important;
  }

}

th {
  @include type-scale(11);
  text-transform: uppercase;
  color: color(regent);
  text-align: left;
  white-space: nowrap;
  padding: rhythm(0.5);
  border-color: color(william);
  @include leading-border(1px, 0.5);
  outline: none;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

.reactable-header-sort-asc,
.reactable-header-sort-desc {
  span {
    position: relative;
    display: inline-block;

    &:after {
      position: absolute;
      top: 0;
      right: - rhythm(1);
      display: block;
      content: "";
      width: rhythm(1);
      height: rhythm(1);
      background: transparent url('../img/arrow-down.svg') no-repeat right top;
    }
  }
}

.reactable-header-sort-asc span:after {
  background: transparent url('../img/arrow-up.svg') no-repeat right top;
}

td {
  @include type-scale(12);
  color: color(bombay);
  padding: rhythm(0.5);
  border-color: color(mercury);
  @include leading-border(1px, 0.5);

  a {
    font-weight: 500;
    color: color(denim);
  }

  img {
    vertical-align: bottom;
  }

  &.td--primary {
    color: color(elephant);
    font-weight: 700;
  }

  &.td--caps {
    text-transform: uppercase;
  }

  &.td--strong {
    font-weight: 500;
  }

  &.td--small {
    @include type-scale(11);
  }

  &.td--large {
    @include type-scale(14);
  }

  &.td--slim {
    font-weight: 100;
  }
}
