.stats-wrap {
  @include container;
  margin-bottom: rhythm(1);

  @include breakpoint($tablet) {
    margin-bottom: rhythm(2);
  }
}

.stats {
  padding: rhythm(1);
  margin-bottom: rhythm(1);
  border-color: #E3E5E7;
  @include trailing-border(1px, 1);

  @include breakpoint($tablet) {
    margin-bottom: 0;
  }

  p {
    font-weight: 500;
    color: color(william);
  }
}

.stats--static {
  padding: rhythm(1);
  background: color(alabaster);
}

.stats__header {
  @include type-scale(32);
  margin-bottom: 0;

  .stats__unit {
    font-weight: 500;
    color: #118DC9;
    @include type-scale(18);
  }
}
