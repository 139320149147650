.creatives-wrap {
  position: relative;
  @include container;
}

.creatives-tool-wrap {
  position: relative;
  @include container;

  &:not(:last-child):before {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    border-color: #E3E5E7;
    z-index: -1;
    width: 100%;
    @include trailing-border(1px, 3);
  }
}

.creative-tool {
  padding: rhythm(2);
  border-color: #E3E5E7;
}

.creative {
  @include span(8 of 12);
  padding: rhythm(2);
  border-color: #E3E5E7;
}

.creative:before {
  content: '';
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  border-color: #E3E5E7;
  z-index: -1;
  @include span(8 of 12);
  @include trailing-border(1px, 3);
}

.creative--last {
  @include span(4 of 12 last);
  padding: rhythm(2) 0;

  &:before {
    left: span(8 of 12) + gutter();
    @include span(4 of 12);
    @include trailing-border(1px, 3);
  }
}

.creative__title {
  @include type-scale(12);
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  margin-bottom: rhythm(1);
}

.creative__number {
  color: white;
  padding: rhythm(0.25);
  display: inline-block;
  background: color(elephant);
  width: rhythm(1.5);
  height: rhythm(1.5);
  text-align: center;
  border-radius: 50%;
  position: absolute;
  left: - rhythm(2);
  top: - rhythm(0.25);
}

.creative__image {
  max-height: 300px;
}

.creative__code code {
  @include type-scale(12);
  border: 1px solid color(mercury);
  border-radius: 5px;
  font-weight: 400;
}


.creative__press-kit {
  text-align: center;
  margin: 3rem 0;
  h4 {
    margin-bottom: .5rem;
  }
  .creative__press-kit__links-wrapper {
    margin: 1rem;
    a {
      margin: .5rem 0;
    }
    .separator--horizontal {
      margin: 0 1rem;
      &:before {
        content: '|';
      }
    }
  }
}