.grid {
  @include container(70rem);
  padding: 0 rhythm(1);
}

.grid--breathe {
  @include breakpoint($tablet) {
    margin-top: rhythm(6);
  }
}

.grid__half {
  @include breakpoint($tablet) {
    @include span(6 of 12);
  }
}

.grid__third {
  @include breakpoint($tablet) {
    @include span(4 of 12);
  }
}

.grid__two-thirds {
  @include span(8 of 12);
}

.grid__quarter {
  @include breakpoint($tablet) {
    @include span(3 of 12);
  }
}

.grid__last {
  @include breakpoint($tablet) {
    @include last;
  }
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.relative {
  position: relative;
}

body {
  // background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAUCAYAAABMDlehAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAsSAAALEgHS3X78AAAAMklEQVQI133KoRGAMAAEsPQNpmOg2H+iSuwPUCSHITp8tT2CGVzBCu7gDIa2w7+3tN0PMwUOoHwY3hwAAAAASUVORK5CYII=');
}

.chart {
  margin-bottom: rhythm(2);

  @include breakpoint($tablet) {
    position: relative;
    left: -40px;
  }
}

.breathe--bottom {
  margin-bottom: rhythm(2);
  display: inline-block;
}

.intro {
  position: absolute;
  width: 100%;
  height: 60%;
  display: table;
  text-transform: uppercase;
  color: color(denim);
  font-weight: 500;
}

.intro__text {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.divider {
  position: relative;
  display: flex;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: rhythm(0.75);
  margin-bottom: rhythm(1.2);
  color: color(bombay);
  z-index: 20;
  @include type-scale(13);

  &:before {
    content: " ";
    position: absolute;
    background: white;
    width: rhythm(1.5);
    height: rhythm(1.5);
    top: - rhythm(0.25);
    left: 50%;
    transform: translate(-50%, 0);
    z-index: -10;
  }

  &:after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    left: 0;
    top: 45%;
    background-color: color(bombay);
    opacity: 0.25;
    z-index: -20;
  }

  &--fullWidth {
    &:before {
      content: none;
    }
  }

  &--thin {
    &:after {
      height: 1px;
    }
  }
}

.tooltip {
  background: color(bombay);
  opacity: 0.5;
  color: white;
  font-weight: 700;
  display: inline-block;
  height: rhythm(1);
  width: rhythm(1);
  text-align: center;
  border-radius: 50%;
  vertical-align: top;
  margin-left: rhythm(0.25);
}

.__react_component_tooltip {
  max-width: rhythm(20);
}
