input[type="submit"],
button, .button {
  font-family: $body-font;
  @include type-scale(18);
  font-weight: 500;
  color: white;
  text-transform: uppercase;
  background: color(flamingo);
  border-radius: 5px;
  padding: rhythm(1) rhythm(2);
  cursor: pointer;
  position: relative;
  margin-right: rhythm(0.5);

  &[disabled] {
    background: rgba(color(flamingo), 0.7);
    pointer-events: none;
  }

  &:focus {
    box-shadow: 0 0 0px 3px color(alabaster);
  }

  &:hover {
    background: darken(color(flamingo), 10%);

    &[disabled] {
      background: rgba(color(flamingo), 0.7);
      pointer-events: none;
    }
  }
}

.button--full {
  width: 100%;
}

.button--small {
  @include type-scale(13);
  padding: rhythm(0.5) rhythm(2);
}

.button--medium {
  @include type-scale(13);
  padding: rhythm(0.5) rhythm(0.75);
}

.button--min {
  @include type-scale(11);
  padding: rhythm(0.25) rhythm(0.5);
}

.button--secondary {
  background: color(buttercup);
  &:hover {
    background: darken(color(buttercup), 5%);
  }
}

.button--positive {
  background: color(lima);
  &:hover {
    background: darken(color(lima), 5%);
  }
}

.button--negatory {
  background: color(mandy);
  &:hover {
    background: darken(color(mandy), 5%);
  }
}

.button--round {
  border-radius: 20px;
}

.button--ghost {
  box-sizing: border-box;
  color: color(william);
  background: white;
  border: solid 1px color(mercury);
  &:hover {
    background: darken(color(lily-white), 5%);
    color: color(denim);
    font-weight: bold;
    border: none;
  }
  &:active {
    box-shadow: none;
  }
}

.button--ghost-active {
  color: color(denim);
  background: color(lily-white);
  border: solid 1px color(denim);
  font-weight: bold;
}